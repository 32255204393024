import { defineStore } from "pinia";
export default defineStore("user",{
        state(){
                return{
                        show:false,
                        // 是否是登录状态
                        ifLogin:JSON.parse(window.localStorage.getItem("result")) ? true : false,
                        joinPopupShow:false,
                        anid:'1'
                }
        },
        actions:{

        },
        getters:{
                
        }
})
// import Vuex from "vuex"
// import { createStore } from "vuex"
// 响应动作
// const actions = {

// }
// // 操作数据
// const mutations = {}
// // 储存数据
// const state = {}
// // 创建store
// const store = new Vuex.Store()
// export default createStore({
//         state(){
//            return{
//                 show:true
//            }
//         },
//         mutations:{

//         },
//         actions:{

//         },
//         getters:{
                
//         }
// })