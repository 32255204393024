import { createRouter, createWebHashHistory } from "vue-router";


const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/home.vue"),
        children: [
            {
                path: "/index",
                name: "首页",
                component: () => import("@/views/homePage/index.vue")
            },
            {
                path: "/orderForm",
                name: "订单",
                component: () => import("@/views/orderForm/index.vue")
            },
            {
                path: "/personalCenter",
                name: "我的",
                component: () => import("@/views/personalCenter/index.vue")
            }
        ]
    },
    {
        path: "/chooseStore",
        name: "选择门店",
        component: () => import("@/views/homePage/chooseStore/index.vue")
    },
    {
        path: "/storeStyle",
        name: "门店风采",
        component: () => import("@/views/homePage/storeStyle/index.vue")
    },
    {
        path: "/chooseCoupon",
        name: "选择优惠券",
        component: () => import("@/views/homePage/chooseCoupon/index.vue")
    },
    {
        path: "/personalInformation",
        name: "个人信息",
        component: () => import("@/views/personalCenter/personalInformation/index.vue")
    },
    {
        path: "/personalBindChangePhone",
        name: "绑定更换手机号",
        component: () => import("@/views/personalCenter/personalInformation/personalBindChangePhone/index.vue")
    },
    {
        path: "/personalDataStatistics",
        name: "数据统计",
        component: () => import("@/views/personalCenter/personalDataStatistics/index.vue")
    },
    {
        path: "/barberRankingStatistics",
        name: "理发师排行",
        component: () => import("@/views/personalCenter/personalDataStatistics/barberRankingStatistics/index.vue")
    },
    {
        path: "/barberDataStatistics",
        name: "理发师效率分析",
        component: () => import("@/views/personalCenter/personalDataStatistics/barberDataStatistics/index.vue")
    },
    {
        path: "/personalCoupon",
        name: "活动营销管理",
        component: () => import("@/views/personalCenter/personalCoupon/index.vue")
    },
    {
        path: "/createCoupon",
        name: "创建优惠券",
        component: () => import("@/views/personalCenter/personalCoupon/createCoupon.vue")
    },
    {
        path: "/detailsCoupon",
        name: "优惠券详情",
        component: () => import("@/views/personalCenter/personalCoupon/detailsCoupon.vue")
    },
    {
        path: "/personalPermission",
        name: "权限管理",
        component: () => import("@/views/personalCenter/personalPermission/index.vue")
    },
    {
        path: "/personalPermissionOperate",
        name: "添加编辑权限管理",
        component: () => import("@/views/personalCenter/personalPermission/save.vue")
    },
    {
        path: "/memberSelect",
        name: "添加成员",
        component: () => import("@/views/personalCenter/personalPermission/memberSelect.vue")
    },
    {
        path: "/personalPost",
        name: "岗位管理",
        component: () => import("@/views/personalCenter/personalPost/index.vue")
    },
    {
        path: "/personalStore",
        name: "我的门店管理",
        component: () => import("@/views/personalCenter/personalStore/index.vue")
    },
    {
        path: "/createStore",
        name: "添加门店",
        component: () => import("@/views/personalCenter/personalStore/createStore/index.vue")
    },
    {
        path: "/storeManagement",
        name: "门店管理",
        component: () => import("@/views/personalCenter/personalStore/storeManagement/index.vue")
    },
    {
        path: "/citySelection",
        name: "选择城市",
        component: () => import("@/views/personalCenter/personalStore/citySelection/index.vue")
    },
    {
        path:"/mapPage",
        name:"地图页面",
        component:()=>import("@/views/personalCenter/personalStore/mapPage/index.vue")
    },
    {
        path: "/personalManagementEdit",
        name: "人员管理编辑",
        component: () => import("@/views/personalCenter/personalStore/personalManagement/edit.vue")
    },
    {
        path: "/personalSelect",
        name: "人员选择",
        component: () => import("@/views/personalCenter/personalStore/personalSelect/index.vue")
    },
    {
        path: "/createBarberProject",
        name: "理发项目",
        component: () => import("@/views/personalCenter/personalStore/createBarberProject/index.vue")
    },
    {
        path: "/personalBarberProject",
        name: "平台理发项目",
        component: () => import("@/views/personalCenter/personalBarberProject/index.vue")
    },
    {
        path: "/personalData",
        name: "个人数据",
        component: () => import("@/views/personalCenter/personalData/index.vue")
    },
    {
        path: "/operationRecords",
        name: "操作记录",
        component: () => import("@/views/operationRecords/index.vue")
    }
]

export default createRouter({
    history: createWebHashHistory(),
    routes: routes
})